import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { VSpacing, Link } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { TwoFactorCheckStatus, LoginSteps } from 'src/components/AccountLogin/types';
import Compensation, { CompensationProps } from 'src/components/Compensation';
import translation from 'src/components/translation';
import usePostponedVacancy from 'src/hooks/usePostponedVacancy';
import { useSelector } from 'src/hooks/useSelector';

import Header from 'src/components/AccountLogin/components/Title/Header';

const TrlKeys = {
    addAutoSearch: 'applicant.login.header.auto.search',
    postponedVacancy: 'applicant.login.header.postponed',
    completeResume: 'applicant.login.header.complete.resume',
    profOrientation: 'applicant.login.header.prof.orientation',
    vacancySavedSearch: 'applicant.login.header.vacancy.saved.search',
    forbiddenTitle: 'logon.forResponse.title',
    header: 'applicant.login.header',
    subHeader: 'applicant.login.subheader',
    subHeaderResponse: 'applicant.login.subheader.response',
    subHeaderFavorite: 'applicant.login.subheader.favorite',
    mailruErrorFirstDescription: 'error.password.mismatch.password.reset.first.paragraph',
    mailruErrorSecondDescription: 'error.password.mismatch.password.reset.second.paragraph',
    twoFactorAuthTitle: 'auth.twoFactor.title',
    twoFactorTotpDescription: 'auth.twoFactor.totp.description',
    twoFactorDescription: 'auth.twoFactor.description',
    twoFactorAuthAccountBlockedDescription: 'auth.twoFactor.accountBlocked.description',
    twoFactorAuthAccountBlockedDescriptionSupport: 'auth.twoFactor.accountBlocked.descriptionSupport',
    twoFactorPushDescription: 'auth.twoFactor.push.description',
};

interface ApplicantLoginTitleProps {
    step: LoginSteps;
    verificationType: TwoFactorCheckStatus | null;
}

const ApplicantLoginTitle: TranslatedComponent<ApplicantLoginTitleProps> = ({ trls, step, verificationType }) => {
    const { search } = useLocation();
    const forbidden = useSelector(({ forbidden }) => forbidden);
    const {
        hasPostponedVacancy,
        postponedVacancyInFavorites,
        hasPostponedAutoSearchAdd,
        hasPostponedCompleteResume,
        hasPostponedVacancySavedSearchOpen,
        postponedVacancy,
    } = usePostponedVacancy();

    const searchParams = new URLSearchParams(search);
    const hideTitle = searchParams.get('hideTitle');

    let header: string | null = trls[TrlKeys.header];
    let dataQa: string | undefined;
    let subheader: ReactNode = null;

    if (hasPostponedAutoSearchAdd) {
        header = trls[TrlKeys.addAutoSearch];
        dataQa = 'account-postponed-autosearch-add';
    }

    if (postponedVacancyInFavorites) {
        header = trls[TrlKeys.postponedVacancy];
        dataQa = 'account-postponed-favorite';
    }

    if (hasPostponedVacancy) {
        header = trls[TrlKeys.postponedVacancy];
        dataQa = 'account-postponed-vacancyresponse';
    }

    if (hasPostponedVacancy && postponedVacancy) {
        subheader = (
            <>
                {trls[TrlKeys[postponedVacancyInFavorites ? 'subHeaderFavorite' : 'subHeaderResponse']]}{' '}
                <p>
                    <Link
                        Element={SPALink}
                        to={`/vacancy/${postponedVacancy.vacancyId}`}
                        data-qa="account-postponed-vacancyresponse"
                        inline
                    >
                        {postponedVacancy.name}{' '}
                        <Compensation
                            {...(postponedVacancy.compensation as unknown as CompensationProps)}
                            analyticsContext="ApplicantLoginTitle"
                        />
                    </Link>
                </p>
            </>
        );
    }

    if (hasPostponedCompleteResume) {
        header = trls[TrlKeys.completeResume];
        dataQa = 'account-postponed-complete-resume';
    }

    if (hasPostponedVacancySavedSearchOpen) {
        header = trls[TrlKeys.vacancySavedSearch];
        dataQa = 'account-postponed-vacancy-saved-search';
    }

    if (forbidden) {
        header = trls[TrlKeys.forbiddenTitle];
    }

    if (step === LoginSteps.MailruError) {
        subheader = (
            <>
                <p>{trls[TrlKeys.mailruErrorFirstDescription]}</p>
                <p>{trls[TrlKeys.mailruErrorSecondDescription]}</p>
            </>
        );
    }

    if (step === LoginSteps.AccountBlocked) {
        subheader = (
            <div data-qa="account-login-form">
                <p>{trls[TrlKeys.twoFactorAuthAccountBlockedDescription]}</p>
                <VSpacing default={8} />
                <p>{trls[TrlKeys.twoFactorAuthAccountBlockedDescriptionSupport]}</p>
            </div>
        );
    }

    if (step === LoginSteps.TwoFactorStep) {
        dataQa = 'login-two-factor-title';
        header = trls[TrlKeys.twoFactorAuthTitle];
        subheader = <div data-qa="account-login-form">{trls[TrlKeys.twoFactorDescription]}</div>;

        if (
            verificationType === TwoFactorCheckStatus.TwoFactorTotpCheck ||
            verificationType === TwoFactorCheckStatus.TwoFactorTotpCheckWrongCode
        ) {
            subheader = <div data-qa="account-login-form">{trls[TrlKeys.twoFactorTotpDescription]}</div>;
        } else if (verificationType === TwoFactorCheckStatus.TwoFactorPushCheck) {
            subheader = <div data-qa="account-login-form">{trls[TrlKeys.twoFactorPushDescription]}</div>;
        }
    }

    if (!header || hideTitle) {
        return null;
    }

    return (
        <Header dataQa={dataQa} subheader={subheader}>
            {header}
        </Header>
    );
};

export default translation(ApplicantLoginTitle);
